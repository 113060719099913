<template>
    <div>
        <row ref="row" :base="$options.name"></row>
    </div>
</template>

<script>
    import row from '@/components/row.vue'

    export default {
        name: "baMuCheng",
        components:{
            row
        },
        data() {
            return {
                list:[
                    {
                        title: "使用前检查",
                        text:[
                            '#初始环境必须成功(金乌岛场景会失败,退出金乌岛再初始环境,如果失败看菜单初始环境教程)(图1)',
                        ],
                        img:[
                            "com/init.png",
                        ]
                    },
                    {
                        title: "功能说明",
                        text:[
                            "#切换到金乌岛设置页",
                            "#按图1说明参数:",
                            "1.必填项-切换输出套路:输出套路是指你的角色主套路,用来打怪的,根据快捷栏设置切换快捷键" +
                            "(9小代表小键盘的9按键,其他带小的是同理)",
                            "2.必填项-切换五气堡套路:五气堡武学修炼专用,此功能无需设置",
                            "3.必填项-技能:上面切换套路后,技能对应的快捷键,根据快捷栏设置",
                        ],
                        img:[
                            "com/jinwu.png",
                        ]
                    },
                    {
                        title: "功能说明",
                        text:[
                            "#切换到八目城页",
                            "#按图1说明参数:",
                            "1.必填项-坐标,设置刷怪的位置坐标(如图1)",
                            "2.默认是一直刷,可以勾选后设置停止的时间,时间以电脑时间为准,当前时间大于设定时间即停止",
                            "3.建议在下一步任务输入移动坐标,否则定时停止后会被怪打死",
                        ],
                        img:[
                            "1.png",
                            "2.png",
                        ]
                    },
                    {
                        title: "游戏说明",
                        text:[
                            "#确保坐标位置,角色可以自动寻路",
                            '以上设置好,在金乌岛场景开始任务',
                        ],
                        img:[
                        ]
                    },
                ],
            }
        },
        methods:{

        },
        mounted() {
            setTimeout(res =>{
                this.$refs.row.initData(this.list);
                console.log(this.$options.name);
            },100)
        }
    }
</script>

<style scoped>

</style>
